/*
* 업무구분: 리크루팅
* 화면 명: 신용카드인증(MSPRC130M)
* 화면 설명: 신용카드 인증
* 화면 접근권한:
*/
<template>
  <ur-page-container :show-title="true" type="subpage" title="신용카드인증">
    <ur-box-container direction="column" alignV="start">
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-verify">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="verify-box pd5">
                <mo-text-field ref="custNmRef" v-model="custNm" :rules="validateRule1" underline placeholder="고객명을 입력해 주세요" maxlength="10" class="form-input-name"
                  @blur="fn_NextComp('knbFrnoRef')"/>
                <div class="verify-birth">
                  <mo-text-field ref="knbFrnoRef" v-model="knbFrno" :rules="validateRuleKnbFrno" type="number" mask="######" underline class="birth-input1" 
                  placeholder="생년월일" />
                  <span>-</span>
                  <mo-text-field ref="knbBknoRef" v-model="knbBkno" mask="#" type="number" :rules="validateRule1" underline class="birth-input2" placeholder="0"/>
                  <em>******</em>
                </div>
                <div class="verify-phone">
                  <msp-bottom-select :items="phoneItems" v-model="celnoFrno" underline />
                  <mo-text-field ref="celnoRef" v-model="celnoBkno" underline placeholder="00000000" :rules="validateRuleCelno" type="number" mask="########"/>
                </div>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="verify-btn ns001">
            <mo-button class="ns-btn-round"  v-if="!authConfirm" @click="fn_Validation">인증하기</mo-button>
            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-check">
              <mo-checkbox class="is-checked" readonly v-if="authConfirm">인증되었습니다.</mo-checkbox>
            </ur-box-container>
            <span class="txt gray">
              &#8251; 주의사항
              <span class="txt-ment">
                1. 법인카드, 가족카드, 선불카드는 본인 확인이 불가합니다.
              </span>
              <span class="txt-ment">
                2. 휴대폰에 착신전환, 수신거부, 로밍 등의 설정이 되어 있는 경우 서비스 이용에 불가능할 수 있습니다.
              </span>
              <span class="txt-ment">
                3. 카드사에 등록된 고객님의 휴대폰 번호가 변경되었거나 잘못 등록된 경우 변경 후 이용하시기 바랍니다.
              </span>
            </span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" 
                @click="fn_GoNext" :disabled="!authConfirm">다음</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </mo-validate-watcher>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import Screen from '~systems/mixin/screen'
export default {
     /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPRC130M",
    screenId: "MSPRC130M",
    mixins: [Screen],
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      let lv_Vm = this

      // ---------------------------------------------------------------------------------
      // 공통업무코드 조회 및 설정 처리
      // ---------------------------------------------------------------------------------
      this.$commonUtil.selListEACommCd('ZA_CELPH_KNB_CD').then(function () {
        // 휴대전화 앞자리 정보 세팅
        let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')

        lv_Vm.phoneItems.push({value: '0', text: '선택'})
        if (lv_CelphCD.length > 0) {
          lv_CelphCD.forEach(
            (item) => {
              let lv_Item = {}
              lv_Item.value = item.key
              lv_Item.text = item.key
              lv_Vm.phoneItems.push(lv_Item)
            }
          )
        }

        // 휴대폰번호 앞자리 초기값은 '010'
        lv_Vm.celnoFrno = lv_Vm.phoneItems[1].value

        lv_Vm.$nextTick(() => {
          lv_Vm.$refs['custNmRef'].focus()
        })
      })
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPRC130M')
    },
    beforeDestroy () {
      this.$BottomManager.fn_SetBottomVisible(true)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        phoneItems: [],
        custNm: '',
        knbFrno: '',
        knbBkno: '',
        celnoFrno: '',
        celnoBkno: '',
        celno: '',
        athntMthScCd: '2', // 인증수단
        authConfirm: false,
        authTest: false,
        mobileNumber: '',
        validateRule1: [
          v => !!v || '필수입력항목입니다'
        ],
        validateRuleKnbFrno: [
          v => !!v || '필수입력항목입니다',
          v => v.length > 5 || '생년월일을 입력하세요.'
        ],
        validateRuleCelno: [
          v => !!v || '필수입력항목입니다',
          v => v.length > 7 || '휴대폰번호를 입력하세요.'
        ]
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      knbFrno (data) {
        if (data.length === 6) {
          this.fn_NextComp ('knbBknoRef')
        }
      },
      knbBkno (data) {
        if (data.length === 1) {
          this.fn_NextComp ('celnoRef')
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 인증번호 버튼 클릭 시 유효성 체크
      ******************************************************************************/
      fn_Validation () {
        let ret = this.$refs.vWatcher.validate()
        
        if (ret === true) {
          let lv_Vm = this
          if (lv_Vm.celnoFrno === '0') { // 핸드폰앞자리가 선택으로 되어 있는 경우
            lv_Vm.celnoFrno = lv_Vm.phoneItems[1].value
          }

          lv_Vm.celno = lv_Vm.celnoFrno + lv_Vm.celnoBkno
          let phone = lv_Vm.celno.replace(/\-/g, '')

          let birth = this.$bizUtil.getBirthDateFromKnb(lv_Vm.knbFrno + lv_Vm.knbBkno)
          let sexCd = 'F'
          if (lv_Vm.knbBkno === '1' || lv_Vm.knbBkno === '3' || lv_Vm.knbBkno === '5' || lv_Vm.knbBkno === '7') {
            sexCd = 'M'
          }
          

          console.log('신용카드인증 정보:' + lv_Vm.custNm + ' :: ' +  birth + ' :: ' + sexCd + ' :: ' + phone)

          if (lv_Vm.$commonUtil.isMobile()) { // 모바일환경에서만 호출
            window.fdpbridge.exec('CardCertPlugin', {
              custNm: lv_Vm.custNm,
              birth: birth,
              sexCd: sexCd,
              phone: phone,
              cpCode: 'RO9900000155'
              // cpCode: 'RO9900000116'
            }, function (result) {
              if (result.data === 'Y') {
                lv_Vm.fn_authFinish()
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '신용카드인증실패')
              }
            }, function (result) {
            // 에러콜백
              lv_Vm.getStore('confirm').dispatch('ADD', '신용카드인증실패')
            })
          } else {
            // 모바일환경아닌 경우
            lv_Vm.fn_authFinish()
          }
          
        }
      },
      /******************************************************************************
      * Function명 : fn_authFinish
      * 설명       : 신용카드 인증 완료 후 인증유형, 인증번호 저장을 위한 함수 호출
      ******************************************************************************/
      fn_authFinish () {
        let lv_Vm = this
        
        // 인증정보 저장
        let pParams = {
          custNm: lv_Vm.custNm, // 고객명
          rrn: lv_Vm.rrn, // 고객주민번호
          knb: 'CM', // 담당업무코드
          selfAthntUsScCd: '03', // 본인인증용 구분코드 02:전자서명 본인인증, 03: 본인인증
          userId: lv_Vm.cnsltNo,
          athntNo: '',
          athntMthScCd: lv_Vm.athntMthScCd, // 본인인증용 구분코드
          celno: lv_Vm.celno.replace(/\-/g, '')
        }

        let trnstId = 'txTSSRC10I2'
        this.post(lv_Vm, pParams, trnstId, 'I')
          .then(function (response) {
            console.log('msgCd : ', response.msgComm.msgCd)
            if (response.body !== null && response.body.wkRslt === 0) {
              console.log('인증정보 저장 OK')
              lv_Vm.authConfirm = true
              lv_Vm.fn_GoNext() // 고객등록페이지 이동
            } else {
              console.log('인증정보 저장 Fail')
            }
          })
      },
      /******************************************************************************
      * Function명 : fn_GoNext
      * 설명       : 후보자등록화면으로 이동
      ******************************************************************************/
      fn_GoNext () {
        let custCardVO = {}
        custCardVO.custNm = this.custNm
        custCardVO.knbFrno = this.knbFrno
        custCardVO.knbBkno = this.knbBkno
        let tmpCelno = this.celnoFrno + this.celnoBkno
        custCardVO.celno = tmpCelno.replace(/\-/g, ''), // 핸드폰 번호
        this.$router.push( { name: 'MSPRC150M', params: { custCardVO: custCardVO} } )
      },
      fn_NextComp (param) {
        if (param === 'knbFrnoRef') { // 이름에서 focus out 되었을 때
          if (this.$bizUtil.isEmpty(this.custNm)) {
            return
          }
        } else if (param === 'knbBknoRef') { // 주민번호 앞자리에서 focus out 되었을 때
          if (this.knbFrno.length < 6) {
            return
          }
        } else if (param === 'celnoRef') { // 주민번호 앞자리에서 focus out 되었을 때
          if (this.knbBkno.length < 1) {
            return
          }
        }

        this.$refs[param].focus()
      }
    }
  }
</script>